import { Filmomtale } from '@filmweb/sanity-types';
import { getFilmUrlFromEdi, IMovieArticle } from 'lib/types/sanity';
import Link from "next/link";
import SNoPoster from "components/NoPoster";
import styled from 'styled-components';
import CMSImage from 'components/images/Image';
import isKinoklubb from 'lib/isKinoklubb';
import { toRem } from "lib/client/fonts";
//#region [Styles]
type SPosterContainerProps = {
	$isKinoklubb?: boolean;
}

const SPosterContainer = styled.div<SPosterContainerProps>`
	display: block;
	position: relative;

	border-bottom: 7px solid var(--color_posters_border, var(--color_primary));

	overflow: hidden;
	background-color: black;
	aspect-ratio: 271 / 384;
	box-sizing: content-box;

	@supports not (aspect-ratio: 271 / 384) {
		height: 0;
		padding-bottom: 141%
	}

	${props => props.$isKinoklubb && `
		&::after {
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			width: 50%;
			height: 0;
			padding-bottom: 50%;
			background-image: url(/KinoklubbRibbon.svg);
			background-repeat: no-repeat;
		}
	`}
`;

const SPosterImage = styled(CMSImage)`
	width: 100%;
	height: auto;
	display: block;
`;

const STextContainer = styled.div`
	position: relative;
	margin: 16px 8px 8px 8px;
`;

const SMovieTitle = styled.h1`
	font-weight: normal;
	font-size: 1.125rem;
	overflow: hidden;
	font-family: "Open Sans",Helvetica,Arial,sans-serif;
	margin: 0;
	text-align: center;
	height: 2.8em;
	position: relative;
	color: var(--color_fpposter_text_mobile, var(--color_fpposter_text, #000));

	@media screen and (min-width: 641px) {
		font-size: 1rem;
		color: var(--color_fpposter_text, #000);
	}

	&::after {
		display: block;
		position: absolute;
		content: "";
		bottom: 0;
		right: 0;
		width: 100%;
		height: 1.4em;
		background-color: transparent;
		background-image: linear-gradient(to right, rgba(var(--color_fpposter_bg_mobile_rgb, var(--color_fpposter_bg_rgb, 254,254,254)),0) 50%, rgba(var(--color_fpposter_bg_mobile_rgb, var(--color_fpposter_bg_rgb, 254,254,254)), 1) 100%);

		@media screen and (min-width: 641px ) {
			background-image: linear-gradient(to right, rgba(var(--color_fpposter_bg_rgb, 254, 254, 254),0) 50%, rgba(vvar(--color_fpposter_bg_rgb, 254, 254, 254), 1) 100%);
		}
	}
`;

const SPosterLabel = styled.div`
	font-size: ${toRem(12)};
	line-height: 1;
	padding: 0.3rem 0.3rem;
	background-color: var(--color_posters_border, var(--color_primary));
	color: var(--color_fpposter_text, #fff) !important;
	position: absolute;
	top: -16px;
	text-align: center;
	left: 0;
	width: 100%;
	max-width: 100%;
	transform: translateY(calc(-50% - 3.5px));


	@media screen and (min-width: 641px ) {
		transform: translate(-50%, calc(-50% - 3.5px));
		left: 50%;
		max-width: 80%;
		width: 80%;

	}
`;

//#endregion

//#region [Props]


type MoviePosterProps = {
	className?: string;
	filmomtale?: Filmomtale | null;
	sizes?: string;
	label?: string;
	fallbackTitle?: string;
	edi: string;
	fwpakkeFilmomtale?: IMovieArticle;
	allowTitleOverride?: boolean;
};
//#endregion

//#region [Component]
export default function MoviePoster({ className, edi, fallbackTitle, label, fwpakkeFilmomtale, filmomtale, allowTitleOverride = false, sizes = "(min-width: 1440px) 270px, 116px" }: MoviePosterProps) {
	const poster = filmomtale?.mainPosterV2 ?? filmomtale?.postersV2?.[0];
	let title = filmomtale?.title ?? fallbackTitle;
	if (allowTitleOverride && fwpakkeFilmomtale?.titleOverride) {
		title = fwpakkeFilmomtale?.titleOverride;
	}

	return <Link href={getFilmUrlFromEdi(filmomtale?.mainVersionEDI ?? edi)} >
		<SPosterContainer $isKinoklubb={!!filmomtale && isKinoklubb(filmomtale)} className={className}>
			{poster && <SPosterImage image={poster} sizes={sizes} isFilmweb altText={`Plakat for '${filmomtale?.title}'`} crop isPosterAspect />}
			{!poster && <SNoPoster />}
		</SPosterContainer>
		<STextContainer>
			{label && <SPosterLabel>{label}</SPosterLabel>}
			<SMovieTitle>{title}</SMovieTitle>
		</STextContainer>
	</Link>;
}
//#endregion