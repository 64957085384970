import styled, { css } from "styled-components";

//#region [Styles]
const SProgramSection = styled.section`
	padding: 10px;
	@media screen and (min-width: 641px) {
		padding: 0;
	}

	@media screen and (max-width: 640px) {
		background-color: var(--color_section_bg_mobile, transparent);
		>h1 {
			color: var(--color_section_text_mobile, var(--color_text, #000));
		}

		h1 {
			margin-top: 0;
		}
	}

	margin-bottom: 50px;
`;

const SError = styled.p`
	color: red;
	text-align: center;

	pre {
		display: none;
	}
`

const SNoShows = styled.p`
	text-align: center;
	grid-column-start: 1;
	grid-column-end: 3;
	@media screen and (min-width: 641px) {
		grid-column-end: 6;
	}
`;

type SShowsContainerProps = {
	$count: number;
	$columns?: number;
};

const SShowsContainer = styled.div<SShowsContainerProps>`
	display: grid;
	margin-bottom: 16px;
	grid-template-columns: ${props => css`repeat(${props.$columns ?? 3}, minmax(0, 1fr))`};
	grid-template-rows: auto;
	gap: 15px;

	${props => props.$count < 3 && props.$count !== 0 && css`
		grid-template-columns: repeat(2, minmax(0, 1fr));
		margin: 0 auto 16px auto;
	`}

	@media screen and (min-width: 641px) {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		>* {
			flex: 0 0 calc(20% - (60px / 5) );
		}
	}



`;



export { SError, SNoShows, SShowsContainer, SProgramSection };

//#endregion