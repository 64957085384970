
import { Filmomtale } from "@filmweb/sanity-types";
import MoviePoster from "components/movieposter/MoviePoster";
import TicketButton from "components/TicketButton";
import { IMovieArticle } from "lib/types/sanity";
import Show from "lib/types/Show";
import styled from "styled-components";


//#region [Styles]
const SOneShowWithPoster = styled.article`
	background-color: var(--color_fpposter_bg_mobile, var(--color_fpposter_bg, #fefefe));

	@media screen and (min-width: 641px ) {
		background-color: var(--color_fpposter_bg, #fefefe); // default to standard color if the inversion is not set
	}

`;

const STicketButton = styled(TicketButton)`
	margin: 8px;
`

//#endregion

//#region [Props]
type OneShowWithPoster = {
	show: Show;
	filmomtale?: Filmomtale;
	showDate?: boolean;
	fwpakkeFilmomtale?: IMovieArticle;
	allowTitleOverride?: boolean;
};
//#endregion

//#region [Component]
export default function OneShowWithPoster({ show, filmomtale, fwpakkeFilmomtale, showDate = false, allowTitleOverride = false }: OneShowWithPoster) {

	return <SOneShowWithPoster>
		<MoviePoster edi={show.movieMainVersionId} fallbackTitle={show.movieTitle} filmomtale={filmomtale} label={show.showType} fwpakkeFilmomtale={fwpakkeFilmomtale} allowTitleOverride={allowTitleOverride}/>
		<STicketButton show={show} showDate={showDate} showPassed/>
	</SOneShowWithPoster>
}
//#endregion