import { format, differenceInYears } from "date-fns";
import locale from "date-fns/locale/nb";
import Show from "lib/types/Show";
import Link from "next/link";
import { useCallback } from "react";
import styled, { css } from "styled-components";



//#region [Styles]
type SShowLinkProps = {
	$isPassed?: boolean;
}

const SSHowLink = styled.a<SShowLinkProps>`
	background: var(--color_ticketbutton_bg, #000000);
	color: var(--color_ticketbutton_text, #ffffff);
	display: block;
	line-height: 1.1;
	border-radius: 0.3em;
	overflow: hidden;

	&:hover {
		text-decoration: none;

		time {
			text-decoration: underline;
		}
	}

	${props => props.$isPassed && css`
		pointer-events: none;
	`}
`;

type SMetaInfoProps = {
	$uppercase?: boolean;
	$scaleDownMobile?: boolean;
	$isPassed?: boolean;
};

const SMetaInfo = styled.span<SMetaInfoProps>`
	font-size: 1rem;
	text-align: center;
	display: block;
	padding: 3px;

	@media screen and (max-width: 640px) {
		word-break: normal;
		overflow-wrap: anywhere;

		${props => props.$scaleDownMobile && css`
			font-size: 0.8rem;
		`}

	}

	${props => props.$uppercase && css`
		text-transform: uppercase;
	`}

	${props => props.$isPassed && css`
		opacity: 0.5;
	`}

`;

const STime = styled.time<SShowLinkProps>`
	text-align: center;
	display: block;

	//font-size: 2rem;
	font-size: 32px; // We use pixels to ensure text size adjustment does not kick in. (it is big enough as it is)
	font-weight: 800;

	${props => props.$isPassed && css`
		opacity: 0.5;
	`}

`;

const SDate = styled(STime)`
	background: var(--color_ticketbutton_datebg, var(--color_primary));
	color: var(--color_ticketbutton_datetext, #ffffff);
	line-height: 1.5;
	text-transform: capitalize;

	font-weight: normal;
	font-size: 1rem;
	padding: 3px;


`;

const SShowType = styled.span`
	font-weight: bold;
`;

//#endregion

//#region [Props]
type TicketButtonProps = {
	show: Show;
	className?: string;
	showDate?: boolean;
	showPassed?: boolean;
	appendShowType?: boolean;
};
//#endregion

const excludeTags: string[] = ["Norsk tekst", "2D"];


//#region [Component]
export default function TicketButton({ show, className, appendShowType = false, showDate = false, showPassed = false }: TicketButtonProps) {
	const gtmHandler = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
		gtmEvent(show);
	}, [show]);

	if (!showPassed && !show.ticketSaleUrl) {
		return null;
	}



	/*
	let metaInfo: string[] = [];
	const is4DX = show.versionTags?.find(({ tag }) => tag === '4DX') ? true : false;
	const is3D = show.versionTags?.find(({ tag }) => tag === '3D') ? true : false;
	const norskTale = show.versionTags?.find(({ tag }) => tag === 'Norsk tale') ? true : false;
	const originalTale = show.versionTags?.find(({ tag }) => tag === 'Original tale') ? true : false;
	const samiskTale = show.versionTags?.find(({ tag }) => tag === 'Samisk tale') ? true : false;
	const isAtmos = show.versionTags?.find(({ tag }) => tag === 'Atmos') ? true : false;
	const isUtekstet = show.versionTags?.find(({ tag }) => tag === 'Utekstet') ? true : false;
	*/
	const versionTags = show.versionTags?.filter(({ tag }) => !excludeTags.includes(tag)) ?? [];
	versionTags.sort((a, b) => {
		if( a.tag.endsWith(" tale") && !b.tag.endsWith(" tale")) {
			return -1;
		}
		if( !a.tag.endsWith(" tale") && b.tag.endsWith(" tale")) {
			return 1;
		}

		if( a.tag.includes("tekst") && !b.tag.includes("tekst")) {
			return 1;
		}
		if( !a.tag.includes("tekst") && b.tag.includes("tekst")) {
			return -1;
		}
		return 0;
	});

	/*
	if (norskTale) metaInfo.push("Norsk tale");
	if (originalTale) metaInfo.push("Original tale");
	if (samiskTale) metaInfo.push("Samisk tale");
	if (isAtmos) metaInfo.push("Atmos");
	if (is4DX) metaInfo.push("4DX");
	if (is3D) metaInfo.push("3D");
	if (isUtekstet) metaInfo.push("Utekstet");
	*/

	const versionText = versionTags.length === 0 ? "\u00A0" : versionTags.map(t => t.tag).join(", ");

	const presentableTime = format(show.showStartDate, 'HH:mm', { locale });

	let ariaLabel = [];
	if (showDate) {
		ariaLabel.push(format(show.showStartDate, 'eeee dd.MM.yyyy', { locale }));
	}
	ariaLabel.push(show.screenName)
	ariaLabel.push(presentableTime);
	ariaLabel.push(versionText);
	if (appendShowType && show.showType) {
		ariaLabel.push(show.showType);
	}

    const displayFormat = differenceInYears(show.showStartDate, new Date()) >= 1 ? "eee dd.MM.yyyy": "eeee dd.MM"

	return <Link href={show.ticketSaleUrl ?? "#"} passHref={!!show.ticketSaleUrl} legacyBehavior  >
		<SSHowLink className={className} $isPassed={!show.ticketSaleUrl} aria-label={ariaLabel.join(' ').trim()} data-edi={show.movieVersionId} target="_blank" onClick={gtmHandler}>
			{showDate && <SDate className="showDate" dateTime={format(show.showStartDate, 'yyyy-MM-dd', { locale })}>{format(show.showStartDate, displayFormat, { locale })}</SDate>}
			<SMetaInfo $uppercase $isPassed={!show.ticketSaleUrl}>{show.screenName}</SMetaInfo>
			<STime dateTime={presentableTime} $isPassed={!show.ticketSaleUrl}>{presentableTime}</STime>
			<SMetaInfo $scaleDownMobile $isPassed={!show.ticketSaleUrl}>{versionText}{appendShowType && show.showType && versionTags.length > 0 && ', '}{appendShowType && show.showType && <SShowType>{show.showType}</SShowType>}</SMetaInfo>
		</SSHowLink>
	</Link>
}
//#endregion


//#region [Other]
function gtmEvent(show: Show): void {
	if (window?.dataLayer) {
		//console.debug("EDI", show.movieVersionId);
		//console.debug("Title", show.movieTitle);

		window?.dataLayer?.push({
			'event': 'Billettsalg',
			'eventCategory': 'Billettsalg',
			'eventAction': "AddToCart",
			'eventLabel': show.movieTitle,
			'eventEdi': show.movieVersionId
		});
	}

}
//#endregion