import styled from "styled-components";

//#region [Styles]
const SNoPoster = styled.div`
	background-color: #cacaca;
	overflow: visible;
	position: relative;
	height: 0;
	padding-bottom: 147%;

	&::before {
		content: "";
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		background-image: url('/icons/Filmramme.svg');
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: 90% auto;
		background-repeat: no-repeat;
		opacity: 0.1;
	}

	&::after  {
		content: "Plakat mangler";
		z-index: 1;
		position: absolute;
		top: 45%;
		width: 100%;
		display: block;
		text-align: center;
		color: currentColor;
	}
`;
//#endregion

export default SNoPoster;
