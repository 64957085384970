import { toRem } from "lib/client/fonts";
import styled from "styled-components";

//#region [Styles]
const SSectionHeader = styled.h1`
	font-size: ${toRem(19)};
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: calc(1em - 3px);
	text-align: center;

	&::after {
		content: "";
		display: block;
		width: 100px;
		margin: 0 auto;
		height: 3px;
		background-color: var(--color_local_border_override, var(--color_primary));
	}


	@media screen and (min-width: 641px) {
		font-size: ${toRem(31)};
		margin-bottom: calc(1em - 7px);

		&::after {
			height: 7px;
		}
	}
`;

export default SSectionHeader;
//#endregion