import Settings from "lib/types/Settings";
import Link from "next/link";
import styled from "styled-components";

//#region [Styles]
const SLinkContainer = styled.div`
	text-align: center;
	margin-top: 1em;
`;

const SAllShowsLink = styled.a`
	border: none;
	margin: 0;
	display: inline-block;
	text-align: center;
	padding: 0.3rem 0.6rem;
	background: var(--color_bgcolor_allshows, var(--color_ticketbutton_bg, #000000));
	color: var(--color_textcolor_allshows, var(--color_ticketbutton_text, #ffffff));
`;
//#endregion

//#region [Props]
type AllShowsLinkProps = {
	settings: Settings;
	showAllShowsFunction?: () => void;
};
//#endregion

//#region [Component]
export default function AllShowsLink({ settings, showAllShowsFunction }: AllShowsLinkProps) {
	return <SLinkContainer>
		{!showAllShowsFunction && <Link href="/program" passHref legacyBehavior>
			<SAllShowsLink>
				{settings.text_allshows ?? "Se hele programmet"}
			</SAllShowsLink>
		</Link>}
		{showAllShowsFunction && <SAllShowsLink as="button" onClick={showAllShowsFunction}>{settings.text_allshows ?? "Se hele programmet"}</SAllShowsLink>}
	</SLinkContainer>
}
//#endregion