import SSectionHeader from "components/framework/SSectionHeader";
import AllShowsLink from "components/frontpage/cinemaprogram/AllShowsLink";
import { SError, SNoShows, SProgramSection, SShowsContainer } from "components/frontpage/cinemaprogram/FrontPageShowsStyles";
import OneShowWithPoster from "components/frontpage/cinemaprogram/OneShowWithPoster";
import PleaseWait from "components/loader/PleaseWait";
import IProgram from "lib/types/Program";
import { IMovieArticle } from "lib/types/sanity";
import Settings from "lib/types/Settings";
import styled from "styled-components";

//#region [Styles]
const SHeader = styled(SSectionHeader)`
	margin-top: 25px;
	--color_local_border_override: var(--color_programelements_header_border);
`;
//#endregion

//#region [Props]
type PosterShowsDisplayProps = {
	settings: Settings;
	heading?: string;
	isLoading?: boolean;
	isLoadingMore?: boolean;
	isError?: boolean;
	error?: object | unknown;
	data?: IProgram;
	hideMoreLink?: boolean;
	showDate?: boolean;
	showAllShowsFunction?: () => void
}
//#endregion

//#region [Component]
export default function PosterShowsDisplay({ settings, heading, isLoading = false, isLoadingMore = false, isError = false, error, data, hideMoreLink = false, showDate = false, showAllShowsFunction }: PosterShowsDisplayProps) {

	return <SProgramSection id="program">
		{heading && <SHeader>{heading}</SHeader>}
		{isLoading && <PleaseWait />}
		{!isLoading && <>
			{isError && <SError>Det oppstod en aldri så liten feil...<pre>{JSON.stringify(error)}</pre></SError>}
			{!isError && <>
				<SShowsContainer $count={data?.shows?.length ?? 0} $columns={2}>
					{data?.shows?.map(show => <OneShowWithPoster key={`${show.movieVersionId}|${show.showStart}|${show.ticketSaleUrl}`} show={show} fwpakkeFilmomtale={data.fwpakkeArticles?.[show.movieMainVersionId] as IMovieArticle} allowTitleOverride={!!settings.canOverrideMovieTitles} filmomtale={data.filmwebMovies?.[show.movieMainVersionId]} showDate={showDate}/>)}
					{(data?.shows?.length ?? 0) === 0 && <SNoShows>For øyeblikket ingen forestillinger</SNoShows>}
				</SShowsContainer>

			</>}
			{!hideMoreLink && <AllShowsLink settings={settings} showAllShowsFunction={showAllShowsFunction} />}
		</>}
		{!isLoading && isLoadingMore && <PleaseWait />}

	</SProgramSection>

}
//#endregion