import styled from "styled-components";

//#region [Styles]
const SLoaderContainer = styled.div`
	width: 192px;
	margin: 0 auto;
	text-align: center;

`;

const Spinner = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: 0 auto;
	border-top: 7px solid var(--color_text) ;
	border-right: 7px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
//#endregion

//#region [Props]
type PleaseWaitProps = {
	text?: string;
};
//#endregion

//#region [Component]
export default function PleaseWait({ text = "Henter innhold..." }: PleaseWaitProps) {
	return <SLoaderContainer>
		<Spinner />
		<p>{text}</p>
	</SLoaderContainer>
}
//#endregion